// Taskbar.js
import React, { useEffect, useState } from 'react';
import './Taskbar.css';
import win from '../images/windows-logo.png';

const Taskbar = ({ onAppClick, darkMode, setDarkMode }) => {
  const [startMenuOpen, setStartMenuOpen] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Update the time and date every second
  useEffect(() => {
    const timer = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleStartMenu = () => setStartMenuOpen(!startMenuOpen);

  const formattedTime = currentDateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  const formattedDate = currentDateTime.toLocaleDateString();

  return (
    <div className={`taskbar ${darkMode ? 'dark' : ''}`}>
      {/* Start Button */}
      <div className="start-button" onClick={toggleStartMenu}>
        <img src={win} alt="Start" />
        Start
      </div>

      {/* Start Menu */}
      {startMenuOpen && (
        <div className={`start-menu ${darkMode ? 'dark' : ''}`}>
          <ul>
            <li onClick={() => { onAppClick('Mini Game'); toggleStartMenu(); }}>
              <span>Mini Game</span>
            </li>
            <li onClick={() => { onAppClick('Contacts'); toggleStartMenu(); }}>
              <span>About me</span>
            </li>
            <li onClick={() => setDarkMode(prev => !prev)}>
              <span>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </li>
            <li>
              <span>More Coming soon</span>
            </li>
          </ul>
        </div>
      )}

      {/* Clock and Date Display */}
      <div className="taskbar-clock">
        <div>{formattedTime}</div>
        <div>{formattedDate}</div>
      </div>
    </div>
  );
};

export default Taskbar;
