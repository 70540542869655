import React from 'react';
import './Window.css';

const ContactsModal = ({ onClose, darkMode }) => {
  return (
    <div className={`window ${darkMode ? 'dark-mode' : ''}`}>
      <div className={`window-header ${darkMode ? 'dark-mode' : ''}`}>
        <span>Contact Information</span>
        <button onClick={onClose}>X</button>
      </div>
      <div className="window-body">
        <p>Name: Assia ELHAROUNI ALAOUI</p>
        <p>Email: a.elharounialaoui@gmail.com</p>
        <p>
          LinkedIn: <a className="details-link" href="https://www.linkedin.com/in/assia-e-690802207/" target="_blank" rel="noopener noreferrer">LinkedIn Profile</a>
        </p>
        <p>
          GitHub: <a className="details-link" href="https://github.com/lilnoodle01" target="_blank" rel="noopener noreferrer">GitHub Profile</a>
        </p>
      </div>
    </div>
  );
};

export default ContactsModal;
