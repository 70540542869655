// MoreDetails.js
import React from 'react';
import './Window.css';

const MoreDetails = ({ onClose, details, darkMode }) => {
  return (
    <div className={`window ${darkMode ? 'dark-mode' : ''}`}>
      <div className={`window-header ${darkMode ? 'dark-mode' : ''}`}>
        <span>Details</span>
        <button onClick={onClose}>X</button>
      </div>
      <div className="window-body">
        <h3>{details.title}</h3>
        <p>{details.description}</p>
        <ul>
          {details.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MoreDetails;
