import React, { useState, useEffect } from 'react';
import './Window.css';

const MiniGame = ({ onClose, darkMode }) => {
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');
  const [targetNumber, setTargetNumber] = useState(null);
  const [score, setScore] = useState(0);

  // Set initial target number on component mount
  useEffect(() => {
    generateNewNumber();
  }, []);

  const generateNewNumber = () => {
    setTargetNumber(Math.floor(Math.random() * 10) + 1);
    setGuess('');
  };

  const handleGuess = () => {
    const guessedNumber = parseInt(guess);
    if (guessedNumber === targetNumber) {
      setMessage('Correct! Generating a new number...');
      setScore(score + 1); // Increase score
      generateNewNumber(); // Generate a new target number
    } else if (guessedNumber < targetNumber) {
      setMessage('Higher');
    } else {
      setMessage('Lower');
    }
  };

  return (
    <div className={`window ${darkMode ? 'dark-mode' : ''}`} style={{ bottom: '60px', left: '20px' }}>
      <div className={`window-header ${darkMode ? 'dark-mode' : ''}`}>
        <span>Guessing Game</span>
        <button onClick={onClose}>X</button>
      </div>
      <div className="window-body">
        <p>Guess a number between 1 and 10:</p>
        <input
          type="number"
          value={guess}
          onChange={(e) => setGuess(e.target.value)}
          min="1"
          max="10"
        />
        <button onClick={handleGuess} className="guess-button">Guess</button>
        <p>{message}</p>
        <p>Score: {score}</p>
      </div>
    </div>
  );
};

export default MiniGame;
