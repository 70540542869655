import React, { useState } from 'react';
import Draggable from 'react-draggable';
import ProjectsWindow from './ProjectsWindow';
import AchievementsWindow from './AchievementsWindow';
import ContactsModal from './ContactsModal';
import MoreDetails from './MoreDetails';
import MiniGame from './MiniGame';
import Taskbar from './Taskbar';
import contact from '../images/contacts-icon.png';
import prj from '../images/projects-icon.png';
import ach from '../images/achievements-icon.png';
import './Desktop.css';

const Desktop = () => {
  const [showProjects, setShowProjects] = useState(false);
  const [showAchievements, setShowAchievements] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsContent, setDetailsContent] = useState(null);
  const [showMiniGame, setShowMiniGame] = useState(false);
  const [darkMode, setDarkMode] = useState(false); // Dark mode state

  const initialX = window.innerWidth - 900;
  const initialY = 20;
  const overlapOffset = 20;

  const handleDetailsClick = (details) => {
    setDetailsContent(details);
    setShowDetails(true);
  };

  const handleAppClick = (appName) => {
    console.log(`App clicked: ${appName}`); // Log which app is clicked

    switch (appName) {
      case 'Contacts':
        setShowContacts(!showContacts);
        break;
      case 'Projects':
        setShowProjects(!showProjects);
        break;
      case 'Programs & Achievements':
        setShowAchievements(!showAchievements);
        break;
      case 'Mini Game':
        setShowMiniGame(!showMiniGame);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`desktop ${darkMode ? 'dark' : ''}`}>
      <Taskbar onAppClick={handleAppClick} darkMode={darkMode} setDarkMode={setDarkMode} />
      <div className="icons">
        <div className="icon" onClick={() => setShowContacts(!showContacts)}>
          <img src={contact} alt="Contacts" />
          <p>Contacts</p>
        </div>
        <div className="icon" onClick={() => setShowProjects(!showProjects)}>
          <img src={prj} alt="Projects" />
          <p>Projects</p>
        </div>
        <div className="icon" onClick={() => setShowAchievements(!showAchievements)}>
          <img src={ach} alt="Programs & Achievements" />
          <p>Programs & <br />Achievements</p>
        </div>
      </div>

      {/* Draggable Windows with Slight Overlap */}
      {showProjects && (
        <Draggable defaultPosition={{ x: initialX, y: initialY }}>
          <div style={{ width: 'auto', height: 'auto' }}>
            <ProjectsWindow 
              darkMode={darkMode} 
              onClose={() => setShowProjects(false)} 
              onDetailsClick={handleDetailsClick}
            />
          </div>
        </Draggable>
      )}
      {showAchievements && (
        <Draggable defaultPosition={{ x: initialX - overlapOffset, y: initialY + overlapOffset }}>
          <div style={{ width: 'auto', height: 'auto' }}>
            <AchievementsWindow darkMode={darkMode} onClose={() => setShowAchievements(false)} />
          </div>
        </Draggable>
      )}
      {showContacts && (
        <Draggable defaultPosition={{ x: initialX - 2 * overlapOffset, y: initialY + 2 * overlapOffset }}>
          <div style={{ width: 'auto', height: 'auto' }}>
            <ContactsModal darkMode={darkMode} onClose={() => setShowContacts(false)} />
          </div>
        </Draggable>
      )}

      {/* MoreDetails Window */}
      {showDetails && detailsContent && (
        <Draggable defaultPosition={{ x: 0, y: 0 }}>
          <div style={{ width: 'auto', height: 'auto' }}>
            <MoreDetails darkMode={darkMode} onClose={() => setShowDetails(false)} details={detailsContent} />
          </div>
        </Draggable>
      )}

      {/* Mini Game Window */}
      {showMiniGame && (
        <Draggable defaultPosition={{ x: 100, y: 200 }}>
          <div className="mini-game-container">
            <MiniGame darkMode={darkMode} onClose={() => setShowMiniGame(false)} />
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default Desktop;
