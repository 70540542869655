// ProjectsWindow.js
import React from 'react';
import './Window.css';

const ProjectsWindow = ({ onClose, onDetailsClick, darkMode }) => {
  const academicProjects = [
    {
      title: 'PFA2024 | E-Learning Platform with AI Counselor',
      description: 'Development of an AI-enabled job counseling platform.',
      points: [
        'React, Python, Flask, Firebase, Ollama',
        'Features include browsing courses, taking quizzes, and obtaining certificates',
        'Career guidance and chatbot integration',
      ],
    },
    {
      title: 'Mobile Recipe Management App',
      description: 'A recipe management application with secure authentication.',
      points: [
        'Java, SQLite',
        'User experience optimization',
      ],
    },
    {
      title: 'PFA2023 | Restaurant Reservation Chatbot',
      description: 'An automated chatbot for restaurant reservations.',
      points: [
        'HTML, CSS, JavaScript, Python, Flask, MySQL',
      ],
    },
  ];
  
  const internships = [
    {
      title: 'Cloud & Data Automation Intern - Nokia',
      description: 'Cloud deployment and data automation using OpenStack and Docker & 4G/5G analysis with PowerBI and python data automation',
      points: [
        'Jul 2024 – Sept 2024',
        'Key Projects: ',
        'OpenStack Deployment: Led the deployment of a single-node OpenStack cloud environment to streamline application hosting and resource allocation with Docker containerization',
        '4G/5G Analysis with Power BI: Conducted in-depth data analysis on 4G/5G network performance using Power BI, enhancing visibility into network metrics.',
        'Python Automation: Developed Python scripts for automating network configurations files improving efficiency and accuracy in data handling.',
      ],
    },
    {
      title: 'Network Security Intern - Maroc PME',
      description: 'Gained insights into advanced threat management using Fortigate technology.',
      points: [
        'Learned about ANPME operations and Fortigate’s advanced threat management features',
        'Jul 2023 – Aug 2023',
      ],
    },
  ];
  

  return (
    <div className={`window ${darkMode ? 'dark-mode' : ''}`}>
      <div className={`window-header ${darkMode ? 'dark-mode' : ''}`}>
        <span>Projects</span>
        <button onClick={onClose}>X</button>
      </div>
      <div className="window-body">
        <h3>Academic Projects</h3>
        <ul>
          {academicProjects.map((project, index) => (
            <li key={index}>
              {project.title}
              <span className="details-link" onClick={() => onDetailsClick(project)}>
                [Details]
              </span>
            </li>
          ))}
        </ul>
        <h3>Internships</h3>
        <ul>
          {internships.map((internship, index) => (
            <li key={index}>
              {internship.title}
              <span className="details-link" onClick={() => onDetailsClick(internship)}>
                [Details]
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectsWindow;
