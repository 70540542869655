import React, { useState, useEffect } from 'react';
import Desktop from './components/Desktop';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("portfolio loading");

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000); // 3-second loading screen
    const dotInterval = setInterval(() => {
      setLoadingText((prev) =>
        prev.endsWith("...") ? "portfolio loading" : prev + "."
      );
    }, 500);

    return () => {
      clearTimeout(timer);
      clearInterval(dotInterval);
    };
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loading-screen">
          <h1>{loadingText}</h1>
        </div>
      ) : (
        <Desktop />
      )}
    </div>
  );
}

export default App;
